import React, {useEffect, useState} from 'react';
import {fetchBonusRegister, fetchIzipay, fetchUserData} from '../../helpers/commons';
import {getByFetch} from '../../helpers/api';
import {GET_USER_ORDER_COUNT, GET_USER_BONUS_TOPUP_AVAILABLE} from '../../helpers/endpoints';
import {navigateToOrder, navigateToOrderHistory, navigateToTopup} from '../../helpers/navigation';
import iconCredit from '../../images/app/icon_credit.png';
import iconPlus from '../../images/app/icon_plus.png';
import iconHistory from '../../images/app/icon_history.png';
import scanbottle from '../../images/app/scanbottle.png';
import iconWreckedBottle from '../../images/app/wrecked_bottle.png';
import LoginPopup from '../../components/app/LoginPopup';
import {IZIFILL_NAME_DEFAULT_VALUE, UNAUTHORIZED_STATUS_CODE} from '../../helpers/constants';

const Home = (props) => {
  const [izipay, setIzipay] = useState('');
  const [orderCount, setOrderCount] = useState(null);
  const [showLoginContainer, setShowLoginContainer] = useState(false);
  const [name, setName] = useState(IZIFILL_NAME_DEFAULT_VALUE);
  const [isTopupBonusAvailable, setTopupBonusAvaibility] = useState(false);
  const [bonusRegister, setBonusRegister] = useState(false);


  async function fetchOrderCount() {
    const response = await getByFetch(GET_USER_ORDER_COUNT);
    const isAuthorized = response.status !== UNAUTHORIZED_STATUS_CODE;

    if (isAuthorized) {
      const data = await response.json();
      setOrderCount(data);
    }
  }

  async function fetchTopupAvaibility() {
    const response = await getByFetch(GET_USER_BONUS_TOPUP_AVAILABLE);
    const isAuthorized = response.status !== UNAUTHORIZED_STATUS_CODE;

    if (isAuthorized) {
      const data = await response.json();
      setTopupBonusAvaibility(data);
    }
  }

  useEffect(() => {
    async function getIzipay() {
      const izipay = await fetchIzipay();
      setIzipay(izipay);
    };

    async function getUserData() {
      // eslint-disable-next-line no-unused-vars
      const [name, userEmail] = await fetchUserData();
      setName(name);
    }

    async function getBonusRegister() {
      setBonusRegister(await fetchBonusRegister());
    }

    getUserData();
    getIzipay();
    fetchOrderCount();
    fetchTopupAvaibility();
    getBonusRegister();
  }, []);

  return (
    <div onClick={() => {
      if (showLoginContainer) setShowLoginContainer(false);
    }}>
      <div className="d-flex flex-column align-items-center mt-7 px-1 w-100">
        <div className="d-flex p-1 home-izipay-container">
          <img className="home-credit-icon" alt="credit" src={iconCredit}></img>
          <div className="home-credit-info ml-1">
            <h5 className="mb-0">Izipay</h5>
            <div className="d-flex">
              <p>Rp</p>
              <h3>{izipay}</h3>
            </div>
          </div>

          <div onClick={() => {
            orderCount === null ?
              setShowLoginContainer(true) : navigateToOrderHistory();
          }} className="d-flex flex-column align-items-center mr-1">
            <div className="rounded-circle home-plus-container d-flex justify-content-center">
              <img className="home-plus-icon w-100" alt="history" src={iconHistory}></img>
            </div>
            <p className="m-0">History</p>
          </div>
          <div onClick={() => {
            orderCount === null ?
              setShowLoginContainer(true) : navigateToTopup();
          }} className="d-flex flex-column align-items-center mr-1">
            <div className="rounded-circle home-plus-container d-flex justify-content-center">
              <img className="home-plus-icon w-100" alt="plus" src={iconPlus}></img>
            </div>
            <p className="m-0">Top up</p>
          </div>
        </div>
        {orderCount === null ?
          <h5 className="mx-4 mt-1 ta-center">Hey, You already save the environment</h5> :
          <>
            <h5 className="mx-4 mt-1 ta-center">{`Hey, ${name}`}</h5>
            <h5 className="mx-4 mt-1 ta-center">{`You already save`}</h5>
            <h5 className="mx-4 mb-1 ta-center">{`${orderCount} bottles!`}</h5>
          </>
        }

        <img className="home-info-bottle-icon" alt="wrecked bottle" src={iconWreckedBottle}></img>
        <h2 className="mx-6 mt-3 ta-center"><b>Thank you!</b></h2>

        {orderCount === null ?
          <>
            <p onClick={() => setShowLoginContainer(true)}
              className="mt-1 ta-center" >
              <u>Sign in / Register</u>
            </p>
            <p className="mt-0 ta-center text-info">&#9432; Get Rp{(bonusRegister).toLocaleString('en-US')} after you register</p>
          </> :
          isTopupBonusAvailable &&
          <>
            <p className="mt-1 ta-center text-info">&#9432; Keep Hydrated! Get a one-time 100% cashback for your top up until 31 October 2022</p>
          </>
        }

        <LoginPopup bonusRegister={bonusRegister} onLoginSuccess={() => { }} showFreeQuotaText={false} className={`order-login__container ${showLoginContainer ? 'd-block' : 'd-none'}`} />

        <p className="mt-1 mb-0 text-info">Refill</p>
        <img
          onClick={navigateToOrder}
          className="align-self-center home-scan"
          alt="Scan Bottle"
          src={scanbottle}
        />

      </div>
    </div>
  );
};
export default Home;
